






















import { Component, Vue, Watch } from 'vue-property-decorator'
import API from '@/api/products'
import ProductCarousel from '@/components/ProductCarousel'
import { CakeCategoryItem, ProductListItem } from '@/shared/models/cakes'
import BezeButton from '@/components/common/BezeButton.vue'

@Component({
  components: {
    ProductCarousel,
    BezeButton
  }
})
export default class Catalog extends Vue {
  list: Record<string, Array<ProductListItem>> = {}

  get cakeCategories(): Array<CakeCategoryItem> {
    return this.$store.getters.categories
  }

  get cakeCategoriesMap(): Record<string, CakeCategoryItem> {
    return this.$store.getters.categoriesMap
  }

  @Watch('cakeCategories')
  watchForCakeCategories(list: Array<CakeCategoryItem>): void {
    // если напрямую попадаем на страницу категорий, то список доступных категорий может еще не придти из стора
    list.length && this.getCakeCatalogs()
  }

  mounted(): void {
    this.cakeCategories.length && this.getCakeCatalogs()
  }

  getCakeCatalogs(): void {
    let category: CakeCategoryItem
    for (category of this.cakeCategories) {
      const tempCatSlug = category.slug
      category.count && API.getCakes({ category: category.id + '', per_page: 3, status: 'publish' }).then(response => {
        Vue.set(this.list, tempCatSlug, response?.data || [])
      })
    }
  }
}
